@import '@/scss/variables.scss'
@import '~vuetify/src/styles/styles.sass'


.k-clist
  margin-top: 0
  padding-top: 0 !important

.k-nav
  font-size: 20px
  margin-left: 25px

  a
    text-decoration: none
    cursor: pointer

.k-list
  ul
    list-style-type: none
    padding-left: 0px

    li
      min-height: 40px
      padding: 10px 10px 10px 20px
      border-bottom: 1px gray solid
      cursor: pointer

      i
        margin-right: 10px

  .k-folder
    .k-loading-line
      margin: 0
    li
      &:hover
        background: #e8f5e9
